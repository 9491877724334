<div class="container bg-container p-1">
  <ngb-carousel #imageCarousel *ngIf="imgNames.length>0" [animation]="false" [interval]="10000" [pauseOnHover]="false" [showNavigationArrows]="false"
                [showNavigationIndicators]="false" class="animatedCarousel">
    <ng-template (slid)="titleImageActiveIndex = i" *ngFor="let imgName of imgNames; index as i" ngbSlide>
      <div class="wrapper">
        <picture *ngIf="isImageActive(i)">
          <source *ngFor="let width of imgWidths" [srcset]="imgPath+imgName+'-w'+width+imgExtension" media="(max-width: {{width}}px) and (max-height: {{width/3*2}}px)">
          <img [src]="imgPath+imgName+'-w1132'+imgExtension" alt="Blitzshare start page background image {{imgName}}"
               class="carousel" i18n-alt="Alt text of Blitzshare start page background image">
        </picture>
        <div class="carousel-caption center">
          <div class="container main-slogan-container m-0 p-0">
            <h1 class="carousel-headline text-shadow-primary" i18n="Start page headline borrow">Borrow</h1>
            <p class="carousel-paragraph text-shadow-primary" i18n="start page paragraph borrow">all the things you ever wanted.</p>
            <h1 class="carousel-headline text-shadow-primary mt-3" i18n="Start page headline rent out">Rent out,</h1>
            <p class="carousel-paragraph text-shadow-primary" i18n="start page paragraph rent out">what you have, but rarely use.</p>
          </div>
        </div>

      </div>
    </ng-template>
  </ngb-carousel>

  <div class="card-group w-100 pb-3">
    <div class="row w-100 justify-content-start mx-0 px-0">
      <div class="card start-page-card m-0">

        <div class="card-body">
          <h2 class="headline" i18n="start page reasons to borrow">Reasons to borrow on Blitzshare</h2>
          <ul>
            <li class="jumbotron-lead"><b i18n="reason to borrow on blitzshare 1 headline">Try before buying</b><br>
              <p i18n="reason to borrow on blitzshare 1 paragraph">Do you want to try a new <a routerLink="browse/category/Sports">sport</a>, but don't know if you like it? Borrow the equipment on Blitzshare and find out!</p>
            </li>
            <li class="jumbotron-lead"><b i18n="reason to borrow on blitzshare 2 headline">Save money and space</b><br>
              <p i18n="reason to borrow on blitzshare 2 paragraph">Have you ever bought a <a routerLink="browse/category/Tools">tool</a> you only needed once and now it's just lying around? If only there were a better solution...</p>
            </li>
            <li class="jumbotron-lead"><b i18n="reason to borrow on blitzshare 3 headline">Help the environment</b><br>
              <p i18n="reason to borrow on blitzshare 3 paragraph">Share things with others. Save valuable resources. Reduce waste. Reduce emissions.</p>
            </li>
          </ul>
        </div>
        <div class="card-footer center start-page-card-footer">
          <a routerLink="/search">
            <app-mat-icon-button i18n-label="Start browsing" label="Start browsing"></app-mat-icon-button>
          </a>
        </div>
      </div>
      <div class="card start-page-card m-0">

        <div class="card-body">
          <h2 class="headline" i18n="start page reasons to lend">Reasons to lend on Blitzshare</h2>
          <ul>
            <li class="jumbotron-lead"><b i18n="reason to lend on blitzshare 1 headline">Make money</b><br>
              <p i18n="reason to lend on blitzshare 1 paragraph">Do you own a <a routerLink="browse/category/Bikes">bike</a>, a <a routerLink="browse/category/PowerTools">power tool</a>, a <a routerLink="browse/category/Kayaks">kayak</a>, <a routerLink="browse/category/Skiing">skiing equipment</a> or anything else, you don't use every day? Rent them out and earn some money.</p>
            </li>
            <li class="jumbotron-lead"><b i18n="reason to lend on blitzshare 2 headline">Help others follow their passions</b><br>
              <p i18n="reason to lend on blitzshare 2 paragraph">Enable people to do things they could not do otherwise. E.g. if you live near a lake and have a <a routerLink="browse/category/Sups">SUP</a>, rent it out to someone, who can't afford to buy their own one.</p>
            </li>
            <li class="jumbotron-lead"><b i18n="reason to lend on blitzshare 3 headline">Meet new people</b><br>
              <p i18n="reason to lend on blitzshare 3 paragraph">If someone rents your goods, you probably have a common interest with them. Maybe, you'll stay in contact.</p>
            </li>
          </ul>
        </div>
        <div class="card-footer center start-page-card-footer">
          <a routerLink="/listing/create">
            <app-mat-icon-button i18n-label="list an item button" label="List an item"></app-mat-icon-button>
          </a>
        </div>
      </div>
    </div>
  </div>

  <h2 class="headline center px-35" i18n>Browse random category</h2>
  <div class="center">
    <app-categories-carousel></app-categories-carousel>
  </div>

  <div class="card-group w-100 pb-3">
    <div class="row w-100 justify-content-start mx-0 px-0">
      <div class="card start-page-card m-0">
        <div class="card-body">
          <h2 class="headline" i18n="start page how to borrow">How to borrow something on Blitzshare</h2>
          <ol class="jumbotron-lead">
            <li><b i18n="start page how to borrow step 1 headline">Find an item you want to rent.</b>
              <p i18n="start page how to borrow step 1 paragraph">Use the <a routerLink="/search">search</a>, browse <a routerLink="/browse/category">categories</a> or <a routerLink="/browse/latest-listings">latest listings</a>.</p>
            </li>
            <li><b i18n="start page how to borrow step 2 headline">Book the item.</b>
              <p i18n="start page how to borrow step 2 paragraph">Select a pickup and return date and send the lender a booking request. We'll notify you, when the lender accepts your request.</p>
            </li>
            <li *ngIf="payService.isPaymentActive()"><b i18n="start page how to borrow step 3 headline">Pay for the rent.</b>
              <p i18n="start page how to borrow step 3 paragraph">We offer a variety of payment options, e.g. credit card, Giropay and Sofortüberweisung.</p></li>
            <li><b i18n="start page how to borrow step 4 headline">Pick up the item.</b>
              <p i18n="start page how to borrow step 4 paragraph">Have fun or get things done. Or both.</p>
            </li>
            <li><b i18n="start page how to borrow step 5 headline">Return the item.</b>
              <p i18n="start page how to borrow step 5 paragraph">After return you can rate the lender.</p>
            </li>
          </ol>
        </div>
        <div class="card-footer center start-page-card-footer">
          <a routerLink="/search">
            <app-mat-icon-button i18n-label="Go to search" label="Go to search"></app-mat-icon-button>
          </a>
        </div>
      </div>


      <div class="card start-page-card m-0">
        <div class="card-body">
          <h2 class="headline" i18n="start page how to lend">How to lend something on Blitzshare</h2>
          <ol class="jumbotron-lead">
            <li><b i18n="start page how to lend step 1 headline">Prepare some appealing images.</b>
              <p i18n="start page how to lend step 1 paragraph">The image makes the first impression of your item. The better the images, the more borrowers you will attract.</p>
            </li>
            <li><b i18n="start page how to lend step 2 headline">List the item.</b>
              <p i18n="start page how to lend step 2 paragraph"><a routerLink="/listing/create">Create a listing</a>, in which you describe your item, upload the images, set a price and availability.</p>
            </li>
            <li><b i18n="start page how to lend step 3 headline">Wait for bookings.</b>
              <p i18n="start page how to lend step 3 paragraph">We'll notify you, when someone books your item for rent. You can then accept or deny the booking or suggest another time.</p>
            </li>
            <li><b i18n="start page how to lend step 4 headline">Hand over the item.</b>
              <p i18n="start page how to lend step 4 paragraph">The borrower will pick up the item. After both parties confirmed the pickup, you will get your payment.</p>
            </li>
            <li><b i18n="start page how to lend step 5 headline">Get the item back.</b>
              <p i18n="start page how to lend step 5 paragraph">After item return you can rate the borrower.</p>
            </li>
          </ol>
        </div>
        <div class="card-footer center start-page-card-footer">
          <a routerLink="/listing/create">
            <app-mat-icon-button i18n-label="list an item button" label="List an item"></app-mat-icon-button>
          </a>
        </div>
      </div>


    </div>
  </div>

  <h2 class="headline center px-35" i18n>Latest listings</h2>

  <div class="center">
    <app-start-page-latest-listings></app-start-page-latest-listings>

  </div>


  <h2 class="headline center px-35 pt-3" i18n>Secure payment</h2>

  <div class="center">
    <p class="jumbotron-lead"><span i18n>We work together with Mangopay to offer you simple and secure payment options.</span>
      <a href="{{mangopayTosLink}}" i18n i18n-href="Mangopay terms and conditions link" target="_blank">View Mangopay's terms and conditions</a>.</p>
  </div>
  <div class="center">
    <img alt="Mangopay logo" class="mangopay-logo" i18n-alt i18n-alt srcset="./assets/images/logos/mangopay.svg">
  </div>
</div>
