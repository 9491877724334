import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {map, takeUntil} from 'rxjs/operators';
import {BookService} from '../../book/book.service';
import {Transaction} from '../../shared/models/transaction.interface';
import {TitleService} from '../../shared/services/title.service';
import {TransactionState} from '../../shared/enums/transactionState.enum';
import {UserPublic} from '../../shared/models/userPublic.interface';
import * as moment from 'moment';
import {BOOKING_CONFIRMATION_TERMS, CANNOT_BE_UNDONE, CURRENT_BOOKING_PERIOD_WILL_REMAIN_VALID} from '../../shared/constants/strings';
import firebase from 'firebase/app';
import {environment} from '../../../environments/environment';
import {SocialService} from '../../social/social.service';
import {Conversation} from '../../shared/models/conversation.interface';
import {Message} from '../../shared/models/message.interface';
import {UtilService} from '../../shared/util.service';
import {Rating} from '../../shared/models/rating.interface';
import {TransactionRole} from '../../shared/enums/transactionRole.enum';
import {PayService} from '../../pay/pay.service';
import {TransactionPeriodSuggestion} from '../../shared/models/transactionPeriodSuggestion.interface';
import {CurrencyPipe} from '@angular/common';
import {MangopayService} from '../../pay/mangopay.service';
import {Address} from '../../shared/models/address.interface';
import {FunctionsService} from '../../shared/services/functions.service';
import {CountryService} from '../../shared/services/country.service';
import {MILLIS_PER_DAY} from '../../shared/constants/numbers';
import Locale from '../../shared/services/locale';
import {AnalyticsEventName, AnalyticsService} from '../../shared/services/analytics.service';
import {Payment} from '../../shared/models/payment.interface';
import {UserService} from '../../shared/services/user.service';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css'],
})
export class TransactionComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewChecked, AfterContentChecked {
  transaction?: Transaction;
  payment?: Payment;
  transactionUid?: string;
  ratingByLender?: Rating;
  ratingByBorrower?: Rating;
  borrower?: UserPublic;
  lender?: UserPublic;
  ratingReceiver?: UserPublic;

  lenderAddress?: Address;
  lenderPhone?: string | null;

  showBookingDateSelection = false;
  conversation?: Conversation;
  latestMessage?: Message;

  showWriteMessageComponent = false;
  showRateComponent = false;
  messageReceiverUid?: string;
  numberFormatLocale = Locale.numberFormatLocale();
  private transactionStreamUnsubscribe?: () => void;

  constructor(
      protected store: Store<AppState>,
      private userService: UserService,
      private activatedRoute: ActivatedRoute,
      private cdRef: ChangeDetectorRef,
      private titleService: TitleService,
      public payService: PayService,
      private router: Router,
      public utilService: UtilService,
      public countryService: CountryService,
      public mangopayService: MangopayService,
      private socialService: SocialService,
      private functionsService: FunctionsService,
      private currencyPipe: CurrencyPipe,
      private analyticsService: AnalyticsService,
      private bookService: BookService) {
    super(store);
  }

  public get transactionRole(): typeof TransactionRole {
    return TransactionRole;
  }

  public get transactionState(): typeof TransactionState {
    return TransactionState;
  }

  /**
   * Called after a transaction update operation.
   * @param transaction the updated transaction
   */
  onSuccessCallback = (transaction: Transaction) => {
    if (transaction)
      this.transaction = transaction;
  };

  /**
   * Called after a failed transaction update operation.
   * @param errorMessage the reason, why it failed
   * @param transaction the current transaction, if available. This should only be available, if the lastUpdate check failed (the transaction has been updated
   * in between)
   */
  onErrorCallback = (errorMessage: string, transaction?: Transaction) => {
    this.addError(errorMessage);
    if (transaction)
      this.transaction = transaction;
  };

  public updateBookingPeriod(pickupDate: Timestamp, returnDate: Timestamp, numberOfDays: number, pricePerDay: number, fee: number) {
    this.clearAlerts();

    if (!this?.transaction?.uid || !this.user?.uid)
      return;

    if (this.transaction.targetPickupDate?.toMillis() === pickupDate?.toMillis() && this.transaction.targetReturnDate?.toMillis() === returnDate?.toMillis()) {
      this.addWarning($localize`The booking period you suggested is the same as the current booking period.`);
      this.showBookingDateSelection = false;
      return;
    }

    const newPeriodSuggestion: TransactionPeriodSuggestion = {
      pickupDate, returnDate, suggester: this.userIsLender() ? TransactionRole.Lender : TransactionRole.Borrower, numberOfDays, pricePerDay,
    };

    // Also accept the booking, if appropriate
    const newState = this.transaction.state === TransactionState.BookingRequested && this.userIsLender() ? TransactionState.BookingAccepted : undefined;
    this.bookService.updateBookingPeriod(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid,
        this.onSuccessCallback, this.onErrorCallback, newPeriodSuggestion, newState);
    this.addSuccess($localize`New booking period suggestion was sent.`);
    this.showBookingDateSelection = false;
  }

  ngOnInit(): void {

    super.ngOnInit();
    this.titleService.setTitle($localize`Transaction`);

    // Fetch transactionUid from route
    const transactionUid$ = this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$),
        map(paramMap => paramMap.get('transactionUid')),
    );
    transactionUid$.subscribe(transactionUid => {
      this.analyticsService.logEvent(AnalyticsEventName.TRANSACTION_VIEW, {transactionUid});
      this.showBookingDateSelection = false;
      this.showWriteMessageComponent = false;
      this.clearAlerts();
      this.killStream();
      if (transactionUid) {
        this.transactionUid = transactionUid;

        // Fetch once, because of an unsolved bug, which causes no transaction to be shown, although it is loaded
        this.bookService.fetchTransaction(transactionUid).then(wrapper => {
          this.transaction = wrapper.data;
          if (wrapper.errorMessage)
            this.addError($localize`We could not load the transaction\: ${wrapper.errorMessage}`);
          this.onTransactionLoaded();
        });

        // Stream transaction
        this.transactionStreamUnsubscribe = this.bookService.streamTransaction(transactionUid, transaction => {
          if (transaction) {
            this.transaction = transaction;
            this.onTransactionLoaded();
          }
        }, error => {
          this.addError($localize`We could not load the transaction\: ${error}`);
        });
      }
    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.killStream();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  userIsBorrower(): boolean {
    return this.transaction?.borrowerUid === this.user?.uid;
  }

  userIsLender(): boolean {
    return this.transaction?.lenderUid === this.user?.uid;
  }

  onAcceptBooking(): void {
    this.clearAlerts();


    const message = '<p>' + $localize`Rent period: ` + moment(this.transaction?.targetPickupDate.toDate()).format('LLLL') + ' - '
        + moment(this.transaction?.targetReturnDate.toDate()).format('LLLL') + `</p><p>${this.getBookingConfirmationTermsString()}</p>`;
    this.utilService.showConfirmDialog($localize`Are you sure you want to accept the booking?`, message, this.acceptBooking.bind(this));
  }

  getBookingConfirmationTermsString(): string {
    if (this.payService.isPaymentActive())
      return BOOKING_CONFIRMATION_TERMS + ' ';
    else
      return BOOKING_CONFIRMATION_TERMS;
  }

  acceptBooking(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    this.analyticsService.logEvent(AnalyticsEventName.BOOKING_ACCEPTED, {transactionUid: this.transaction.uid});
    this.bookService.acceptBooking(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback);
  }

  onSuggestAnotherBookingPeriod(): void {
    this.clearAlerts();
    this.showBookingDateSelection = true;
    this.utilService.scrollToId('booking-date-selection');
  }


  onRetractBookingPeriodSuggestion(): void {
    this.clearAlerts();
    this.utilService.showConfirmDialog($localize`Are you sure you want to retract the booking period suggestion?`, CURRENT_BOOKING_PERIOD_WILL_REMAIN_VALID, this.retractBookingPeriodSuggestion.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  retractBookingPeriodSuggestion(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    this.bookService.removeBookingPeriodSuggestion(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback, 'SUGGESTION_RETRACTED');
  }

  onDenyNewBookingBookingPeriod(): void {
    this.clearAlerts();
    this.utilService.showConfirmDialog($localize`Are you sure you want to deny the booking period suggestion?`, CURRENT_BOOKING_PERIOD_WILL_REMAIN_VALID, this.denyNewBookingBookingPeriod.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  denyNewBookingBookingPeriod(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    // Note: retracting and denying is basically the same
    this.bookService.removeBookingPeriodSuggestion(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback, 'SUGGESTION_DENIED');
  }

  onAcceptNewBookingPeriod(): void {
    this.clearAlerts();
    let message = $localize`<p>Current booking duration in days\: ${this.transaction?.numberOfDays?.toFixed(2)}</p>
                              <p>Suggested booking duration in days\: ${this.getSuggestedBookingDuration()?.toFixed(2)}</p>`;
    if (this.transaction?.paymentState === 'PAID')
      message += $localize`<div class="alert alert-warning">The price of the booking will stay the same, because the booking is already paid.</div>`;
    const newRentPrice = this.getSuggestedBookingPrice();
    if (this.transaction?.paymentState !== 'PAID' && this.transaction?.pricePerDay && this.transaction.numberOfDays && newRentPrice) {
      message += $localize`<p>Current booking price\: ${this.currencyPipe.transform(this.transaction?.pricePerDay * this.transaction?.numberOfDays, this.transaction.currencyId, 'symbol', undefined, this.numberFormatLocale)}</p>
                                   <p>New booking price\: ${this.currencyPipe.transform(newRentPrice, this.transaction.currencyId, 'symbol', undefined, this.numberFormatLocale)}</p>
        `;
    }
    this.utilService.showConfirmDialog($localize`Are you sure you want to accept the booking period suggestion?`, message, this.acceptNewBookingBookingPeriod.bind(this));
  }

  acceptNewBookingBookingPeriod(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    const numberOfDays = this.getSuggestedBookingDuration();
    this.bookService.acceptBookingPeriodSuggestion(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback, numberOfDays, this.transaction.newPeriodSuggestion);
  }

  onDenyBooking(): void {
    this.clearAlerts();
    this.utilService.showConfirmDialog($localize`Are you sure you want to deny the booking?`, CANNOT_BE_UNDONE, this.denyBooking.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  denyBooking(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    this.analyticsService.logEvent(AnalyticsEventName.BOOKING_DENIED, {transactionUid: this.transaction.uid});
    this.bookService.denyBooking(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback);
  }

  onCancelBooking(): void {
    this.clearAlerts();
    const title = $localize`Are you sure you want to cancel the booking?`;
    if (this.transaction?.paidAmount && this.transaction.paidAmount > 0) {
      const messageTransactionFee = $localize`Our payment provider charged a transaction fee of about 3.5% for that payment, which cannot be refunded.`;
      const messageSuggestAnotherTime = $localize`Instead of cancelling, you could suggest another time.`;
      let message = '';
      if (this.userIsBorrower())
        message = '<p>' + $localize`You already paid for the booking.` + ` ${messageTransactionFee}</p><p>${messageSuggestAnotherTime}</p><p>${CANNOT_BE_UNDONE}</p><p>${title}</p>`;
      if (this.userIsLender())
        message = '<p>' + $localize`The borrower already paid for the booking.` + ` ${messageTransactionFee}</p><p>${messageSuggestAnotherTime}</p><p>${CANNOT_BE_UNDONE}</p><p>${title}</p>`;
      this.utilService.showConfirmDialog(title, message, this.cancelBooking.bind(this), undefined, undefined, undefined, undefined, 'no');
    }
    // Not paid yet
    else
      this.utilService.showConfirmDialog(title, CANNOT_BE_UNDONE, this.cancelBooking.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  cancelBooking(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;

    this.analyticsService.logEvent(AnalyticsEventName.BOOKING_CANCELLED, {transactionUid: this.transaction.uid});
    this.bookService.cancelBooking(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback);
  }

  onRequestItemPickup(receiverUid: string): void {
    this.clearAlerts();
    const message = $localize`This can only be undone, before the other party confirms the pickup. The rent period starts, when both parties have confirmed the pickup.`;
    this.utilService.showConfirmDialog($localize`Are you sure you want to mark the item as picked up?`, message, this.requestItemPickup.bind(this));
  }

  requestItemPickup(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    const newState = this.userIsBorrower() ? TransactionState.ItemPickUpRequestedByBorrower : TransactionState.ItemPickUpRequestedByLender;
    this.bookService.requestItemPickup(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, newState, this.onSuccessCallback, this.onErrorCallback);
  }

  onUndoItemPickup(receiverUid: string): void {
    this.clearAlerts();
    const message = $localize`Only continue, if you marked it as picked up by accident.`;
    this.utilService.showConfirmDialog($localize`Are you sure you want to undo marking the item as picked up?`, message, this.undoItemPickup.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  undoItemPickup(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    this.bookService.undoItemPickup(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback);
  }

  onConfirmItemPickup(receiverUid: string): void {
    this.clearAlerts();
    const message = $localize`If you continue, the rent period starts.`;
    this.utilService.showConfirmDialog($localize`Are you sure you want to confirm the pickup?`, message, this.confirmItemPickup.bind(this));
  }

  confirmItemPickup(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    this.analyticsService.logEvent(AnalyticsEventName.BOOKING_CONFIRMED_ITEM_PICKUP, {transactionUid: this.transaction.uid});
    this.bookService.confirmItemPickup(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback);
  }

  onDeclineItemPickup(receiverUid: string): void {
    this.clearAlerts();
    const message = $localize`Only continue, if the other party incorrectly marked the item as picked up.`;
    this.utilService.showConfirmDialog($localize`Are you sure you want to decline the pickup?`, message, this.declineItemPickup.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  declineItemPickup(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    this.bookService.declineItemPickup(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback);
  }

  onRequestItemReturn(receiverUid: string): void {
    this.clearAlerts();
    const message = $localize`This can only be undone, before the other party confirms the return. The rent period ends, when both parties have confirmed the return.`;
    this.utilService.showConfirmDialog($localize`Are you sure you want to mark the item as returned?`, message, this.requestItemReturn.bind(this));
  }

  requestItemReturn(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    const newState = this.userIsBorrower() ? TransactionState.ItemReturnRequestedByBorrower : TransactionState.ItemReturnRequestedByLender;
    this.bookService.requestItemReturn(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, newState, this.onSuccessCallback, this.onErrorCallback);
  }

  onUndoItemReturn(): void {
    this.clearAlerts();
    const message = $localize`Only continue, if you marked it as returned by accident.`;
    this.utilService.showConfirmDialog($localize`Are you sure you want to undo marking the item as returned?`, message, this.undoItemReturn.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  undoItemReturn(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    this.bookService.undoItemReturn(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback);
  }

  onConfirmItemReturn(): void {
    this.clearAlerts();
    const message = $localize`If you continue, the rent period ends.`;
    this.utilService.showConfirmDialog($localize`Are you sure you want to confirm the return?`, message, this.confirmItemReturn.bind(this));
  }

  confirmItemReturn(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    this.analyticsService.logEvent(AnalyticsEventName.BOOKING_CONFIRMED_ITEM_RETURN, {transactionUid: this.transaction.uid});
    this.bookService.confirmItemReturn(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback);
  }

  onDeclineItemReturn(): void {
    this.clearAlerts();
    const message = $localize`Only continue, if the other party incorrectly marked the item as returned.`;
    this.utilService.showConfirmDialog($localize`Are you sure you want to decline the return?`, message, this.declineItemReturn.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  declineItemReturn(): void {
    if (!this?.transaction?.uid || !this.user?.uid)
      return;
    this.bookService.declineItemReturn(this.transaction.uid, this.transaction?.lastUpdate, this.user.uid, this.onSuccessCallback, this.onErrorCallback);
  }

  onRate(receiverUid: string): void {
    this.clearAlerts();
    if (this.borrower?.uid === receiverUid)
      this.ratingReceiver = this.borrower;
    if (this.lender?.uid === receiverUid)
      this.ratingReceiver = this.lender;
    if (!this.ratingReceiver)
      return;
    this.showRateComponent = true;
    this.utilService.scrollToId('rate-user');
  }

  onSendMessage(receiverUid: string): void {
    this.clearAlerts();
    this.messageReceiverUid = receiverUid;
    this.showWriteMessageComponent = true;
    this.utilService.scrollToId('write-message');
  }

  onPay(): void {
    this.clearAlerts();
    if (!this.transaction || !this.transaction.currencyId) {
      this.addError($localize`There is no valid transaction.`);
      return;
    }

    this.payService.setPaymentParams((this.transaction.pricePerDay * this.transaction.numberOfDays), this.transaction.currencyId, this.transaction, this.transaction?.transactionListing, this.lender);
    this.router.navigate(['pay', 'form']);
  }

  onLoadAddress(userUid: string): void {
    this.clearAlerts();
    if ((this.payService.isPaymentActive() && this.transaction?.paymentState !== 'PAID') || !this.transaction?.uid)
      return;

    this.enableLoadingSpinner($localize`Loading lender's address...`);
    this.functionsService.loadAddress({userUid: userUid, transactionUid: this.transaction.uid}, response => {
          this.lenderAddress = response.address;
          this.lenderPhone = response.phone;
          this.disableLoadingSpinner();
        },
        errorMessage => {
          this.addError(errorMessage);
          this.disableLoadingSpinner();
        },
    );

  }


  /**
   * Determines, if the 'Mark item as picked up' button should be shown or not.
   */
  isPickupAvailable(): boolean {
    if (!this.transaction?.targetPickupDate)
      return true;
    return new Date().getTime() > this.transaction.targetPickupDate.toMillis() - environment.itemPickupReturnToleranceSec * 1000;
  }

  /**
   * Determines, if the 'Mark item as returned' button should be shown or not.
   */
  isReturnAvailable(): boolean {
    if (!this.transaction?.targetReturnDate)
      return true;
    return new Date().getTime() > this.transaction.targetReturnDate.toMillis() - environment.itemPickupReturnToleranceSec * 1000;
  }

  onMessageSent(latestMessage: Message) {
    this.latestMessage = latestMessage;
  }

  onRatingSent(event: { rating: Rating, transaction: Transaction }, receiverUid: string) {
    this.transaction = event.transaction;
    if (event.rating.raterUid === event.transaction.borrowerUid)
      this.ratingByBorrower = event.rating;
    if (event.rating.raterUid === event.transaction.lenderUid)
      this.ratingByLender = event.rating;
  }

  /**
   * Get the other user. If the given user is the lender, the borrower will be returned. If the given user is the borrower, the lender will be returned.
   * @param userUid given user uid
   * @return other user
   */
  getOtherUserPublic(userUid?: string): UserPublic | undefined {
    if (!userUid)
      return undefined;
    if (this.lender?.uid === userUid)
      return this.borrower;
    else
      return this.lender;
  }

  getCurrentBookingDuration(): number | undefined {
    const pickupDate = this.transaction?.targetPickupDate;
    const returnDate = this.transaction?.targetReturnDate;
    if (!pickupDate || !returnDate)
      return undefined;
    return Math.max(1, (returnDate.toMillis() - pickupDate.toMillis()) / MILLIS_PER_DAY);
  }

  getSuggestedBookingDuration(): number | undefined {
    const pickupDate = this.transaction?.newPeriodSuggestion?.pickupDate;
    const returnDate = this.transaction?.newPeriodSuggestion?.returnDate;
    if (!pickupDate || !returnDate)
      return undefined;
    return Math.max(1, (returnDate.toMillis() - pickupDate.toMillis()) / MILLIS_PER_DAY);
  }

  getSuggestedBookingPrice(): number | undefined {
    const pickupDate = this.transaction?.newPeriodSuggestion?.pickupDate;
    const returnDate = this.transaction?.newPeriodSuggestion?.returnDate;
    const pricePerDay = this.transaction?.newPeriodSuggestion?.pricePerDay;
    if (!pickupDate || !returnDate || !pricePerDay)
      return undefined;
    return Math.max(1, (returnDate.toMillis() - pickupDate.toMillis()) / MILLIS_PER_DAY) * pricePerDay;
  }

  /**
   * If the rent period is changed after payment, the rent price stays the same. Therefore, the shown total price is different from the paid price. This method checks, if there is such a difference.
   */
  doesPaidAmountDeviateFromTotalPrice() {
    const paidAmount = this.transaction?.paidAmount;
    if (!paidAmount)
      return false;
    if (this.transaction?.pricePerDay === undefined || this.transaction?.numberOfDays === undefined || this.transaction.currencyId === undefined)
      return false;
    const totalPrice = this.transaction?.pricePerDay * this.transaction?.numberOfDays;
    if (!totalPrice)
      return false;
    return Math.abs(this.mangopayService.convertAmount(paidAmount, this.transaction.currencyId, true) - totalPrice) >= 0.01;
  }

  private killStream() {
    if (this.transactionStreamUnsubscribe)
      this.transactionStreamUnsubscribe();
  }

  private onTransactionLoaded() {
    if (!this.transaction)
      return;
    this.titleService.setTitle($localize`Transaction ${this.transaction.transactionListing.name}`);
    // Fetch borrower and lender
    this.userService.fetchUserPublic(this.transaction.borrowerUid).then(wrapper => {
      this.borrower = wrapper.data;
      if (wrapper.errorMessage)
        this.addError($localize`We could not load the borrower\: ${wrapper.errorMessage}`);
    });
    this.userService.fetchUserPublic(this.transaction.lenderUid).then(wrapper => {
      this.lender = wrapper.data;
      if (wrapper.errorMessage)
        this.addError($localize`We could not load the lender\: ${wrapper.errorMessage}`);
    });

    this.ratingByLender = undefined;
    // Fetch ratings of lender and borrower
    if (this.transaction.ratingByLenderUid)
      this.bookService.fetchRating(this.transaction.ratingByLenderUid).then(wrapper => {
        this.ratingByLender = wrapper.data;
        if (wrapper.errorMessage)
          this.addError($localize`We could not load the lender's rating\: ${wrapper.errorMessage}`);
      });
    this.ratingByBorrower = undefined;
    if (this.transaction.ratingByBorrowerUid)
      this.bookService.fetchRating(this.transaction.ratingByBorrowerUid).then(wrapper => {
        this.ratingByBorrower = wrapper.data;
        if (wrapper.errorMessage)
          this.addError($localize`We could not load the borrower's rating\: ${wrapper.errorMessage}`);
      });

    const conversationUid = this.bookService.createConversationUid(this.transaction.lenderUid, this.transaction.borrowerUid, this.transaction.listingUid);
    this.socialService.fetchConversation(conversationUid).then(wrapper => {
      if (wrapper.data) {
        this.conversation = wrapper.data;
        this.onConversationLoaded(this.conversation);
      }
      // No need to show the error message here. If there is no conversation, firestore will throw a permission-denied error, which we don't care about.
    });

    // Fetch payment
    if (this.transaction.paymentUid)
      this.bookService.fetchPayment(this.transaction.paymentUid).then(wrapper => {
        this.payment = wrapper.data;
        if (wrapper.errorMessage)
          this.addError($localize`We could not load the payment\: ${wrapper.errorMessage}`);
      });

  }

  private onConversationLoaded(conversation: Conversation) {
    if (!conversation.uid)
      return;
    this.socialService.fetchMessagesFromConversation(conversation.uid, 1).then(wrapper => {
      if (wrapper.data)
        this.latestMessage = wrapper.data[0];
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }
}
