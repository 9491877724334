<span *ngIf="periodSuggestionState">
  <span *ngIf="periodSuggestionState === 'SUGGESTED'" i18n="transaction periodSuggestionState SUGGESTED">New rent period suggested</span>
  <span *ngIf="periodSuggestionState === 'SUGGESTION_ACCEPTED'" i18n="transaction periodSuggestionState SUGGESTION_ACCEPTED">Suggestion accepted</span>
  <span *ngIf="periodSuggestionState === 'SUGGESTION_DENIED'" i18n="transaction periodSuggestionState SUGGESTION_DENIED">Suggestion denied</span>
  <span *ngIf="periodSuggestionState === 'SUGGESTION_RETRACTED'" i18n="transaction periodSuggestionState SUGGESTION_RETRACTED">Suggestion retracted</span>
</span>
<span *ngIf="!periodSuggestionState">
  <span *ngIf="!state">None</span>
  <span *ngIf="state === transactionState.BookingRequested" i18n="transaction state BookingRequested">Booking requested</span>
  <span *ngIf="state === transactionState.BookingAccepted && paymentState !== 'PAID'" i18n="transaction state BookingAccepted">Booking accepted</span>
  <span *ngIf="state === transactionState.BookingAccepted && paymentState === 'PAID'" i18n="transaction state paid">Paid</span>
  <span *ngIf="state === transactionState.BookingCancelled && paymentState !== 'REFUNDED'" i18n="transaction state BookingCancelled">Booking cancelled</span>
  <span *ngIf="state === transactionState.BookingCancelled && paymentState === 'REFUNDED'" i18n="transaction state refunded">Refunded</span>
  <span *ngIf="state === transactionState.BookingDenied" i18n="transaction state BookingDenied">Booking denied</span>
  <span *ngIf="state === transactionState.ItemPickedUp && paymentState !== 'TRANSFERRED'" i18n="transaction state ItemPickedUp">Item picked up</span>
  <span *ngIf="state === transactionState.ItemPickedUp && paymentState === 'TRANSFERRED'"
        i18n="transaction state payment transferred">Payment transferred</span>
  <span *ngIf="state === transactionState.ItemPickUpRequestedByBorrower"
        i18n="transaction state ItemPickUpRequestedByBorrower">Borrower marked item as picked up</span>
  <span *ngIf="state === transactionState.ItemPickUpRequestedByLender"
        i18n="transaction state ItemPickUpRequestedByLender">Lender marked item as picked up</span>
  <span *ngIf="state === transactionState.ItemReturned" i18n="transaction state ItemReturned">Item returned</span>
  <span *ngIf="state === transactionState.ItemReturnRequestedByBorrower"
        i18n="transaction state ItemReturnRequestedByBorrower">Borrower marked item as returned</span>
  <span *ngIf="state === transactionState.ItemReturnRequestedByLender"
        i18n="transaction state ItemReturnRequestedByLender">Lender marked item as returned</span>
</span>
